.state-inputs {
  width: 100%;
}
.select-dropdown {
  width: 100%;
}
.state-inputs .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: unset;
}
.state-inputs .MuiInputBase-root {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}
.state-inputs .MuiSelect-root {
  position: relative;
}
.MuiInputBase-root .MuiSelect-select.MuiSelect-select {
  padding-left: 1.5em;
  width: auto;
  font-family: inter;
  font-weight: 700;
  font-size: 2em;
}
.MuiInputBase-root .MuiSelect-selectMenu {
  overflow:unset;
}
.MuiInputBase-root .MuiSelect-select.MuiSelect-selectMenu:focus {
  background-color: unset;
}
.MuiInputBase-root .MuiSelect-selectMenu:focus  {
  background-color: #fff;
}
.state-gate label{
  color: #6d7b88;
}
.MuiSvgIcon-root {
  position: relative;
  bottom: 6px;
  right: 20px;
  pointer-events: none;
  cursor: pointer;
}